<template>
  <div id="AddUser" class="row mx-0">
    <div id="addcupon" class="row mx-0">
      <div id="header" class="col-sm-12 col-md-12  color-bg-4">
        <Header
                :name="user.fullname"
                /> 
      </div>
      <div class="col-sm-12 col-md-12">
        <div id="content" class="row color-bg-4">
           <div id="" class="">
              <SideMenu :indexsec="4"/>
          </div>

          <!--<div class="Content_Radius">-->

          <div id="stn-edituser" class="stn_edituserAdd">
            <div class="Title_AgregarP"><p>AGREGAR CUPÓN</p></div>
            <form
              @submit.prevent="
                submit(
                    name,
                    descrp,
                    code,
                    cstatus,
                    date_a,
                    date_b,
                    freeShipping,
                    totalAvailable,
                    totalAvailableCustomer, 
                    minAmount,
                    maxAmount,
                    type,
                    value,
                    limitCustomers,
                    ExcludeCustomers,
                    limitCategories,
                    ExcludeCategories,
                    limitProducts,
                    ExcludeProducts,
                    couponRestrictionsCustomers,
                    couponRestrictionsProducts,
                    couponRestrictionsCategories,
                )
              "
            >
              <div class="row mx-0 row-one">
                <div class="F1_C1">
                  <label class="labelN color-1" for="inputN">Nombre</label>
                  <b-form-input
                   pattern="[a-zA-ZñÑáéíóúÁÉÍÓÚ0-9\s]{2,254}"
                    v-model="name"
                    required
                    id="inputN"
                    type="text"
                  />
                </div>

                 <div class="">
                  <label class="labeldes color-1" for="inputdes">Descripción</label>
                  <b-form-input
                  
                    v-model="descrp"
                    required
                    id="inputdes"
                    type="text"
                  />
                </div>

                    
                 <div class="">
                  <label class="labelcd color-1" for="inputcd">CÓDIGO</label>
                  <b-form-input
                     pattern="[a-zA-ZñÑáéíóúÁÉÍÓÚ0-9\s]{2,254}"
                    v-model="code"
                    required
                    id="inputcd"
                    type="text"
                  />
                  <p class="gencode" @click="codeCreate()"> GENERAR CÓDIGO 	&#11119;</p>
                </div>
               
               
              </div>

              <div class="row mx-0 row-two">
                  
                 <div class="CEF4_B1">
                  <label class="labelAf color-1" for="inputAf"
                    >Fecha</label
                  >
                  <b-form-input
                    v-model="date_a"
                    required
                    :min="day()"
                    id="inputAf"
                    type="date"
                  />
                </div>
                 <div class="F1_C1">
                  <label class="labelAb color-1" for="inputAb"
                    >Fecha EXP.</label
                  >
                  <b-form-input
                    v-model="date_b"
                    required
                    :min="minday()"
                    id="inputAb"
                    type="date"
                  />
                </div>
              </div>

              <div class="row mx-0 row-two">
                   <div class="CEF4_B1">
                    <label class="labelpr color-1" >Estatus</label>
                   
                     <b-dropdown>
                      <template #button-content>
                        <div class="row"><span class="drop-text">{{cstatus}}</span></div> 
                      </template>

                        <div>
                          <b-dropdown-item @click="changeStatus('ACTIVO')">
                            ACTIVO
                          </b-dropdown-item>
                          <b-dropdown-item @click="changeStatus('INACTIVO')">
                            INACTIVO
                          </b-dropdown-item>
                       </div>
                    </b-dropdown>
                  </div>
                  <div class="F1_C1">
                    <label class="labelpr color-1" >Envío gratis</label>
                   
                     <b-dropdown>
                      <template #button-content>
                        <div class="row"><span class="drop-text">{{freeShipping}}</span></div> 
                      </template>

                        <div>
                          <b-dropdown-item @click="changeShip('SI')">
                            SI
                          </b-dropdown-item>
                          <b-dropdown-item @click="changeShip('NO')">
                            NO
                          </b-dropdown-item>
                       </div>
                    </b-dropdown>
                  </div>
                   
                
              </div>


              


              <div class="row mx-0 row-two">
                   
                   
                 <div class="CEF4_B1">
                  <label class="labeltds color-1" for="inputtds"
                    >Total disponible</label
                  >
                  <b-form-input
                    class="Inputtds"
                   
                    required
                    v-model="totalAvailable"
                    id="inputtds"
                    pattern="\d*"
                    type="number"
                  />
                </div>

                <div class="F1_C1">
                  <label class="labelA color-1" for="inputRop"
                    >NUM. USOS POR Cliente</label
                  >
                  <b-form-input
                    class="Input_td"
                   
                    required
                     v-model="totalAvailableCustomer"
                    id="inputRop"
                    pattern="\d*"
                    type="number"
                  />
                </div>
              </div>


               <div class="row mx-0 row-two">
                   
                     <div class="CEF4_B1">
                  <label class="labeltds color-1" for="inputtds"
                    >Valor</label
                  >
                  <b-form-input
                    class="Inputtds"
                   
                    required
                    v-model="value"
                    id="inputtds"
                    pattern="\d*"
                    type="number"
                  />
                </div>

                <div class="F1_C1">
                    <label class="labelpr color-1" >Tipo</label>
                   
                     <b-dropdown>
                      <template #button-content>
                        <div class="row"><span class="drop-text">{{type}}</span></div> 
                      </template>

                        <div>
                          <b-dropdown-item @click="changeType('IMPORTE')">
                            IMPORTE
                          </b-dropdown-item>
                          <b-dropdown-item @click="changeType('PORCENTAJE')">
                            PORCENTAJE
                          </b-dropdown-item>
                       </div>
                    </b-dropdown>
                  </div>
                 
              </div>



              <div class="row mx-0 row-two">

                
                  
                 <div class="CEF4_B1">
                  <label class="labelmam color-1" for="inputmam"
                    >Importe mínimo</label
                  >
                  <b-form-input
                    class="Inputmam"
                   
                    required
                    v-model="minAmount"
                    id="inputmam"
                    pattern="\d*"
                    type="number"
                  />
                  <p class="naamount">*INDICA 0 SI NO APLICA.</p>
                </div>

                <div class="F1_C1">
                  <label class="labelmxam color-1" for="inputmxam"
                    >Importe maximo</label
                  >
                  <b-form-input
                    class="Inputmxam"
                   
                    required
                     v-model="maxAmount"
                    id="inputmxam"
                    pattern="\d*"
                    type="number"
                  />
                  <p class="naamount">*INDICA 0 SI NO APLICA.</p>
                </div>
              </div>

              <h1 id="title">RESTRICCIONES</h1>

               <div class="row mx-0 row-two">
                     <div class="F1_C1">
                    <label class="labelpr color-1" >Restricción cliente</label>
                   
                     <b-dropdown>
                      <template #button-content>
                        <div class="row"><span class="drop-text">{{resCus}}</span></div> 
                      </template>

                        <div>
                          <b-dropdown-item @click="changeRCus('LIMITAR')">
                            LIMITAR
                          </b-dropdown-item>
                          <b-dropdown-item @click="changeRCus('EXCLUIR')">
                            EXCLUIR
                          </b-dropdown-item>
                           <b-dropdown-item @click="changeRCus('NO')">
                            NO
                          </b-dropdown-item>
                       </div>
                    </b-dropdown>
                  </div>
                   
                <div v-if="resCus != 'NO'" class="">
                  <label class="labelemc color-1" for="inputemc"
                    >EMAIL</label
                  >
                  <b-form-input
                    class="Inputemc"
                   :disabled="resCus=='NO'"
                   
                    v-model="cus_email"
                    id="inputtemc"
                  
                    type="email"
                  />
                </div>

                 <p v-if="resCus != 'NO'" class="addcust" :disabled="resCus=='NO'" @click="añadirCus(cus_email)">AÑADIR</p>

                  


              
                 
              </div>

              <div v-if="resCus != 'NO'" class="TblRCus">
                     <div class="headertbl">
                        <p class="hit1">N.</p>
                        <p class="hit2">EMAIL</p>
                        <p class="hit3">ELIMINAR</p>
                        
                     </div>
                     <div v-if="couponRestrictionsCustomers.length >0">
                       <div  class="items" v-for="(cus , key) in couponRestrictionsCustomers" :key="key">
                       <p class="item1">{{key}}</p>
                       <p  class="item2">{{cus}}</p>
                       <p class="item3" @click="delRcus(key)"> 	&#10006;</p>
                     </div>
                     </div>
                     <div v-else>
                       <p class="pvoid">SIN CLIENTES AÑADIDOS.</p>
                     </div>
                     
                  </div>
                 <hr class="hrm">

           


              <!--  AGREGAR CATEGORIAS -->
              <div class="row mx-0 row-two">
                     <div class="F1_C1">
                    <label class="labelcta color-1" >Restricción categoría</label>
                   
                     <b-dropdown>
                      <template #button-content>
                        <div class="row"><span class="drop-text">{{resCat}}</span></div> 
                      </template>

                        <div>
                          <b-dropdown-item @click="changeRCat('LIMITAR')">
                            LIMITAR
                          </b-dropdown-item>
                          <b-dropdown-item @click="changeRCat('EXCLUIR')">
                            EXCLUIR
                          </b-dropdown-item>
                           <b-dropdown-item @click="changeRCat('NO')">
                            NO
                          </b-dropdown-item>
                       </div>
                    </b-dropdown>
                  </div>
                   
                <div v-if="resCat != 'NO'" class="">
                  <label class="labelrcatd color-1" for="inputrcatd"
                    >CATEGORÍA</label
                  >
                  <b-form-input
                    class="Inputrcatd"
                   :disabled="resCat=='NO'"
                    
                    pattern="[a-zA-ZñÑáéíóúÁÉÍÓÚ0-9\s]{2,254}"
                    v-model="cat_name"
                    id="inputtrcatd"
                  
                    type="text"
                  />
                </div>

                 <p v-if="resCat != 'NO'" class="addcust" :disabled="resCat=='NO'" @click="añadirCat(cat_name)">AÑADIR</p>

                  


              
                 
              </div>

              <div v-if="resCat != 'NO'" class="TblRCus">
                     <div class="headertbl">
                        <p class="hit1">N.</p>
                        <p class="hit2">CATEGORÍA</p>
                        <p class="hit3">ELIMINAR</p>
                        
                     </div>
                     <div v-if="couponRestrictionsCategories.length >0">
                       <div  class="items" v-for="(cus , key) in couponRestrictionsCategories" :key="key">
                       <p class="item1">{{key}}</p>
                       <p  class="item2">{{cus}}</p>
                       <p class="item3" @click="delRcat(key)"> 	&#10006;</p>
                     </div>
                     </div>
                     <div v-else>
                       <p class="pvoid">SIN CATEGORÍAS AÑADIDAS.</p>
                     </div>
                     
                  </div>
                 <hr class="hrm">
             
   


              <div class="row mx-0 row-two">
                     <div class="F1_C1">
                    <label class="labelcta color-1" >Restricción productos</label>
                   
                     <b-dropdown>
                      <template #button-content>
                        <div class="row"><span class="drop-text">{{resProd}}</span></div> 
                      </template>

                        <div>
                          <b-dropdown-item @click="changeRProd('LIMITAR')">
                            LIMITAR
                          </b-dropdown-item>
                          <b-dropdown-item @click="changeRProd('EXCLUIR')">
                            EXCLUIR
                          </b-dropdown-item>
                           <b-dropdown-item @click="changeRProd('NO')">
                            NO
                          </b-dropdown-item>
                       </div>
                    </b-dropdown>
                  </div>
                   
                <div v-if="resProd != 'NO'" class="">
                  <label class="labelrProdd color-1" for="inputrProdd"
                    >PRODUCTO</label
                  >
                  <b-form-input
                    class="InputrProdd"
                   :disabled="resProd=='NO'"
                  
                    pattern="[a-zA-ZñÑáéíóúÁÉÍÓÚ0-9\s]{2,254}"
                    v-model="prod_name"
                    id="inputtrProdd"
                  
                    type="text"
                  />
                </div>

                 <p v-if="resProd != 'NO'" class="addcust" :disabled="resProd=='NO'" @click="añadirProd(prod_name)">AÑADIR</p>

                  


              
                 
              </div>

              <div v-if="resProd != 'NO'" class="TblRCus">
                     <div class="headertbl">
                        <p class="hit1">N.</p>
                        <p class="hit2">PRODUCTO</p>
                        <p class="hit3">ELIMINAR</p>
                        
                     </div>
                     <div v-if="couponRestrictionsProducts.length >0">
                       <div  class="items" v-for="(cus , key) in couponRestrictionsProducts" :key="key">
                       <p class="item1">{{key}}</p>
                       <p  class="item2">{{cus}}</p>
                       <p class="item3" @click="delRprod(key)"> 	&#10006;</p>
                     </div>
                     </div>
                     <div v-else>
                       <p class="pvoid">SIN PRODUCTOS AÑADIDOS.</p>
                     </div>
                     
                  </div>
                 <hr class="hrm">
       
       
            


               <div class="row mx-0 row-one">
                <div class="div-error" v-if="this.status=='error'">
                    <p class="msg-error">{{this.msg}}</p>
                </div>

              </div>
              <div class="btn-form">

                <b-btn 
                  variant="outline-secondary"
                  class="editar-modal "
                  type="submit"
                  >CREAR CUPÓN</b-btn
                >
                <b-modal
                  id="modal-editar"
                  ref="modal-editar"
                  no-close-on-backdrop
                  centered
                  hide-footer
                  hide-header
                >
                  <div class="message-modal">
                    <img :src="IconSuccess" class="success" /><br />
                    <p class="text-modal color-6">El cupón se agrego correctamente</p>
                    
                  </div>
                  <div class="">
                    <div class="btns-dflx">
                      <div class="btn-void"></div>
                      <b-button class="btn-modal"  @click="editarOtro()" >AÑADIR OTRO</b-button>
                      <b-button class="btn-modal"  @click="toEdit()">EDITAR</b-button>
                  
                  
                    </div>
                   
                    <b-button
                      type="button"
                      aria-label="Close"
                      class="close-modal"
                      @click="salir()"
                      >×</b-button
                    >
                  </div>
                </b-modal>
              </div>
            </form>


        <div class="tbls dflx">
             <div class="box_content">

            <div class="comp_tbl_sec">
            <div class="header_tbl_sec">
                <p class="htbl1">PRODUCTOS</p>
             
            </div>
            <div v-if="data != 'No se encontraron coincidencias'" class="content_tbl_sec">
                <div v-for="(item, key) in filteredItems" :key="key" class="item_cont_tbl">
                    <p class="itbl1">{{item.name}}</p>
             
                </div>

                 <div id="navigate-buttons">
                    <div class="dflx">
                     
                      <button @click="prev"   id="btn-next" class="btn-navigate  ">&#8594;</button>
                        <p class="num-pages color-6">{{currentPage}} - {{totalPages}}</p>

                         
                         <button @click="next"   id="btn-prev" class="btn-navigate  ">&#8594;</button>
                     </div>
                </div>
            </div>
            <div v-else class="content_tbl_sec">
                <div class="item_cont_tbl">
                    <p class="itblNA">Sin productos registrados...</p>
                </div>
            </div>

              </div>
        </div>


         <div class="box_content">

            <div class="comp_tbl_sec">
            <div class="header_tbl_sec">
                <p class="htbl1">CATEGORÍAS</p>
             
            </div>
            <div v-if="data != 'No se encontraron coincidencias'" class="content_tbl_sec">
                <div v-for="(item, key) in filteredItems2" :key="key" class="item_cont_tbl">
                    <p class="itbl1">{{item.name}}</p>
             
                </div>

                 <div id="navigate-buttons">
                    <div class="dflx">
                     
                   
                            <button @click="prev2" id="btn-next"  class="btn-navigate  "> &#8594; </button>
                        <p class="num-pages color-6">{{currentPage2}} - {{totalPages2}}</p>
                          <button @click="next2" id="btn-prev"  class="btn-navigate  ">		&#8594;</button>
                       
                     </div>
                </div>
            </div>
            <div v-else class="content_tbl_sec">
                <div class="item_cont_tbl">
                    <p class="itblNA">Sin categorías registrados...</p>
                </div>
            </div>

              </div>
        </div>
        </div>

         



          </div>
          

        <!--</div>-->

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SideMenu from '../../components/SideMenu';
import logo from '../../assets/cimarrones/logocima_2.png';
import IconSuccess from "../../assets/Vector.png";
import legrafica from "../../assets/Legrafica.png";
import categorias from '../../assets/categorias.png';
import usuarios from '../../assets/usuarios.png';
import productos from '../../assets/productos.png';
import Header from '../../components/Header'
import dayjs from 'dayjs'
import { mapActions } from "vuex";
export default {
  name: "AddUser",
   components: {
    Header,
    SideMenu
  },
  data() {
    return {

       
        name:"",
        descrp:"",
        code:"",
        cstatus:"INACTIVO", 
        date_a:"", 
        date_b:"", 
        freeShipping:"NO", 
        totalAvailable:0,
        totalAvailableCustomer:1, 
        minAmount:0,
        maxAmount:0,
        type:"PORCENTAJE",  
        value:0,
        resCus:'NO',
        resProd:'NO',
        resCat:'NO',
        limitCustomers:"NO",  
        ExcludeCustomers:"NO", 
        limitCategories:"NO",  
        ExcludeCategories:"NO", 
        limitProducts:"NO", 
        ExcludeProducts:"NO", 
        couponRestrictionsCustomers: [],
        couponRestrictionsProducts: [],
        couponRestrictionsCategories: [],

        cus_email:"",
        cat_name:"",
        prod_name:"",
     
        status: "",
        msg: "",
      
        
        logo: logo,
        legrafica: legrafica,
        IconSuccess: IconSuccess,
        
        categorias: categorias,
        usuarios: usuarios,
        productos: productos,

      

        added:'',

      

        currentPage: 1,
        itemsPerPage:   4,


        currentPage2: 1,
        itemsPerPage2:   4,
    };
  },async beforeMount() {
        let token = await this.$store.dispatch("admin/getToken");
        this.$store.dispatch("admin/decode", token);
    }, 
  created() {
     this.getCategories('Categorías')
     this.getAllInfo('tienda')
      this.getCupons('qw8-gdw62fs_sdj3Dg3/cupones')
  },
  computed:{
     cupones() {
           return this.$store.getters["cupon/data"];
        }, 
      products() {
           return this.$store.getters["main/data"];
        }, 
    categories() {
       let allctg =this.$store.getters["categories/data"];
       let result =[]
       let model;
       for(var i =0; i<allctg.length; i++){
         if(allctg[i].parent_name != 'BLOG' && allctg[i].name != 'BLOG'){ //Mostrar solo categorias para ropa.
           if(allctg[i].id== 1){
             model ={
                        id: allctg[i].id,
                        id_parent: allctg[i].id_parent,
                        name: 'NINGUNA',
                        parent_name: allctg[i].parent_name
                      }
          }else{
            model ={
            id: allctg[i].id,
            id_parent: allctg[i].id_parent,
            name: allctg[i].name,
            parent_name: allctg[i].parent_name
            }
          }

           result.push(model)
         }
          
         
       }

      return result
    },
    url() {
      return this.$store.getters["main/baseURL"];
    },
    user() {            
        return this.$store.getters['admin/getIdentity'];
    },




    //Paginacion productos
        


       allItems () {
            return Object.keys(this.products).map(pid => this.products[pid])
        },
        filteredItems () {
        
             return  this.allItems.slice((this.currentPage - 1) * this.itemsPerPage, this.currentPage * this.itemsPerPage )
        },
        totalPages () {
            let total = Math.ceil(this.allItems.length / this.itemsPerPage)
                if(total < this.currentPage){
                this.currentPage =1
                }
        return total
       },


       allItems2 () {
            return Object.keys(this.categories).map(pid => this.categories[pid])
        },
        filteredItems2 () {
             return  this.allItems2.slice((this.currentPage2 - 1) * this.itemsPerPage2, this.currentPage2 * this.itemsPerPage2 )
        },
        totalPages2 () {
            let total = Math.ceil(this.allItems2.length / this.itemsPerPage2)
                if(total < this.currentPage2){
                this.currentPage2 =1
                }
        return total
       },


  },
  methods: {
    ...mapActions('cupon', ['getCupons']),
    ...mapActions('main', ['getAllInfo']),
    ...mapActions('categories', ['getCategories']),
    wait: function () {
      setTimeout(() => this.$router.go(), 200);
    },
    submit: async function (name,
                    descrp,
                    code,
                    cstatus,
                    date_a,
                    date_b,
                    freeShipping,
                    totalAvailable,
                    totalAvailableCustomer, 
                    minAmount,
                    maxAmount,
                    type,
                    value,
                    limitCustomers,
                    ExcludeCustomers,
                    limitCategories,
                    ExcludeCategories,
                    limitProducts,
                    ExcludeProducts,
                    couponRestrictionsCustomers,
                    couponRestrictionsProducts,
                    couponRestrictionsCategories,) {

         if(couponRestrictionsCustomers.length == 0   && (limitCustomers == 'SI' || ExcludeCustomers =='SI')){
                  this.status = 'error';
                  this.msg = 'Falta agregar los clientes a los que desea aplicar la restricción.';
          }else if(couponRestrictionsProducts.length == 0   && (limitProducts == 'SI' || ExcludeProducts =='SI')){
                  this.status = 'error';
                  this.msg = 'Falta agregar los productos a los que desea aplicar la restricción.';
          }else if(couponRestrictionsCategories.length == 0   && (limitCategories == 'SI' || ExcludeCategories =='SI')){
                  this.status = 'error';
                  this.msg = 'Falta agregar las categorias a las que desea aplicar la restricción.';
          }else if( date_a  >= date_b){
                  this.status = 'error';
                  this.msg = 'La fecha inicial no debe ser mayor o igual a la de expiración.';
          }else{


              let data ={   
                    "name":name,
                    "descrp":descrp,
                    "code":code,
                    "cstatus":cstatus, 
                    "date_a":date_a, 
                    "date_b":date_b, 
                    "freeShipping":freeShipping, 
                    "totalAvailable":totalAvailable,
                    "totalAvailableCustomer":totalAvailableCustomer, 
                    "minAmount":minAmount,
                    "maxAmount":maxAmount,
                    "type":type,  
                    "value":value,
                    "limitCustomers":limitCustomers,  
                    "ExcludeCustomers":ExcludeCustomers, 
                    "limitCategories":limitCategories,  
                    "ExcludeCategories":ExcludeCategories, 
                    "limitProducts":limitProducts, 
                    "ExcludeProducts":ExcludeProducts, 
                    "couponRestrictionsCustomers": couponRestrictionsCustomers,
                    "couponRestrictionsProducts": couponRestrictionsProducts,
                    "couponRestrictionsCategories": couponRestrictionsCategories,
              }

                this.status=''
                  this.msg=''
              
                  let result = await this.$store.dispatch("main/addItem",  {option:'wY7-ml3v4Lp9_r2sq6/cupon', item: data});
                
                  if(result.status=='error'){
                    this.status='error'
                    this.msg= result.message
                  }else{ // success
                    
                      this.added = result.added
                      this.showModal() 
                  }


          }
          

     
    },
    editarOtro: function () {
      this.wait();
    },
    salir: function () {
       this.$router.push("/administrador").catch((err) => {});
    },

    

    toEdit: function () {
       this.$router.push("/editar/cupon/"+this.added.id).catch((err) => {});
    },

    changeStatus: function (value) {
        this.cstatus = value
    },

     changeShip: function (value) {
        this.freeShipping = value
     },

     changeType: function (value) {
        this.type = value
     },
      changeRCus: function (value) {
        this.resCus = value
        if(value == 'NO'){
            this.limitCustomers ='NO'
            this.ExcludeCustomers ='NO'
            this.couponRestrictionsCustomers = []
        }

        if(value =='LIMITAR'){
            this.limitCustomers ='SI'
            this.ExcludeCustomers ='NO'
        }

        if(value == 'EXCLUIR'){
            this.limitCustomers ='NO' 
            this.ExcludeCustomers ='SI'
        }
     },

     añadirCus(email){
          this.status =''
          this.msg =''
         
         if(this.resCus=='NO'){
            this.status ='error'
            this.msg ='Es necesario indicar el tipo de restricción (Limitar / Excluir)'

         } else if(email == undefined || email == ''){
             this.status ='error'
             this.msg ='Agrege un email valido.'
         }else{
                let items =[]
                let res =this.couponRestrictionsCustomers;

                for(var i=0; i< res.length; i++){
                    items.push(res[i])
                } 

                let encontrado = 'NO';

                for(var k=0; k< items.length; k++){
                   if(items[k] == email){
                     encontrado='SI'
                   }
                } 

                if(encontrado =='NO'){
                  items.push(email)
                }else{
                   this.status ='error'
                  this.msg ='Email ya agregado.'
                }
               

                this.couponRestrictionsCustomers = items
                this.cus_email =""
         }

        
     },

     delRcus(key){
                let items =[]
                let res =this.couponRestrictionsCustomers;

                for(var i=0; i< res.length; i++){
                  if(i != key){
                      items.push(res[i])
                  }
                } 

                this.couponRestrictionsCustomers = items
                
     },

     /*
      limitCustomers:"NO",  
        ExcludeCustomers:"NO", 
        limitCategories:"NO",  
        ExcludeCategories:"NO", 
        limitProducts:"NO", 
        ExcludeProducts:"NO", 
        */


       changeRCat: function (value) {
        this.resCat = value
        if(value == 'NO'){
            this.limitCategories ='NO'
            this.ExcludeCategories ='NO'
            this.couponRestrictionsCategories = []
        }

        if(value =='LIMITAR'){
            this.limitCategories ='SI'
            this.ExcludeCategories ='NO'
        }

        if(value == 'EXCLUIR'){
            this.limitCategories  ='NO' 
            this.ExcludeCategories ='SI'
        }

     },

     añadirCat(categoria){
          this.status =''
          this.msg =''
         
         if(this.resCat=='NO'){
            this.status ='error'
            this.msg ='Es necesario indicar el tipo de restricción (Limitar / Excluir)'

         } else if(categoria == undefined || categoria == ''){
             this.status ='error'
             this.msg ='Agrege una categoría valida.'
         }else{
                let cate = this.categories;
                let isvalid ='NO' ;
                for(var i =0; i<cate.length;  i++){
                    if(cate[i].name == categoria){
                      isvalid= 'SI'
                    }
                }


                if(isvalid =='SI'){
                let items =[]
                let res =this.couponRestrictionsCategories;

                for(var i=0; i< res.length; i++){
                    items.push(res[i])
                } 

                let encontrado = 'NO';

                for(var k=0; k< items.length; k++){
                   if(items[k] == categoria){
                     encontrado='SI'
                   }
                } 

                if(encontrado =='NO'){
                  items.push(categoria)
                }else{
                   this.status ='error'
                  this.msg ='Categoría ya agregada.'
                }
               

                this.couponRestrictionsCategories = items
                this.cat_name =""
                }else{
                  this.status ='error'
                  this.msg ='Categoría no encontrada.'
                  this.cat_name =""
                }
         }

        
     },

     delRcat(key){
                let items =[]
                let res =this.couponRestrictionsCategories;

                for(var i=0; i< res.length; i++){
                  if(i != key){
                      items.push(res[i])
                  }
                } 

                this.couponRestrictionsCategories = items
                
     },




     changeRProd: function (value) {
        this.resProd = value
        if(value == 'NO'){
            this.limitProducts ='NO'
            this.ExcludeProducts ='NO'
            this.couponRestrictionsProducts = []
        }

        if(value =='LIMITAR'){
            this.limitProducts ='SI'
            this.ExcludeProducts ='NO'
        }

        if(value == 'EXCLUIR'){
            this.limitProducts  ='NO' 
            this.ExcludeProducts ='SI'
        }

     },

     añadirProd(producto){
          this.status =''
          this.msg =''
         
         if(this.resProd=='NO'){
            this.status ='error'
            this.msg ='Es necesario indicar el tipo de restricción (Limitar / Excluir)'

         } else if(producto == undefined || producto == ''){
             this.status ='error'
             this.msg ='Agrege una categoría valida.'
         }else{
                let prods = this.products;
                let isvalid ='NO' ;
                for(var i =0; i<prods.length;  i++){
                    if(prods[i].name == producto){
                      isvalid= 'SI'
                    }
                }


                if(isvalid =='SI'){
                let items =[]
                let res =this.couponRestrictionsProducts;

                for(var i=0; i< res.length; i++){
                    items.push(res[i])
                } 

                let encontrado = 'NO';

                for(var k=0; k< items.length; k++){
                   if(items[k] == producto){
                     encontrado='SI'
                   }
                } 

                if(encontrado =='NO'){
                  items.push(producto)
                }else{
                   this.status ='error'
                  this.msg ='Producto ya agregado.'
                }
               

                this.couponRestrictionsProducts = items
                this.prod_name =""
                }else{
                  this.status ='error'
                  this.msg ='Producto no encontrado.'
                  this.prod_name =""
                }
         }

        
     },

     delRprod(key){
                let items =[]
                let res =this.couponRestrictionsProducts;

                for(var i=0; i< res.length; i++){
                  if(i != key){
                      items.push(res[i])
                  }
                } 

                this.couponRestrictionsProducts = items
                
     },
    
    
    

    showModal(){
       this.$refs['modal-editar'].show()
    },

    day(){  
            return dayjs().format('YYYY-MM-DD')
    },
    minday(){    
            return dayjs().add(1,'days').format('YYYY-MM-DD')   
    },








    next () {
          
          if ( this.currentPage < this.totalPages) {
            this.currentPage++
          }
    },
    prev () {
      if (this.currentPage > 1) {
        this.currentPage--
      }
    },


    
    next2 () {
          
          if ( this.currentPage2 < this.totalPages2) {
            this.currentPage2++
          }
    },
    prev2 () {
      if (this.currentPage2 > 1) {
        this.currentPage2--
      }
    },

      codeCreate(){
        let cupones = this.cupones
        this.code = ""

        
        var ctrs = "ABCDEFGHJKLMNOPQRTUVWXYZ123456789";
        var codeGen = "";




        let valido = false;
        while(  valido == false ) {
           

            for (var k = 0; k < 20; k++) {
                codeGen += ctrs.charAt(Math.floor(Math.random() * ctrs.length));
            }

            
            let encontrado ='no'
            for(var i=0; i < cupones.length; i++){
               
                if(cupones[i].code == codeGen){
                    encontrado = 'si'
                }

            }

            if(encontrado == 'si'){
                codeGen = ''

            }else{
                valido = true
            }
           
        } 
       
        

       this.code = codeGen;
        
        return codeGen
    },
  },
};
</script>

<style scoped>
   
    h1#title{
        color: var(--color-7);
        font-weight: 900;
        font-size: 2vw;
        margin-top: 2vw;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHYAAAAMCAYAAAC3Hkm2AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAA4SURBVHgB7dFBFQAQAEAx3EXQP4J49Phvq7B593mDnDVIEhslNkpslNgosVFio8RGiY0SGyU26gOwDgH4MhHt5QAAAABJRU5ErkJggg==);
        background-size: 56.5vw;
        background-repeat: no-repeat;
        background-position-x: 8vw;
        background-position-y: 2.2vw;
        margin-bottom: 2.5vw;
        text-align: center;

    }

    p.addcust{
        
        margin-top: 1.5vw;
        margin-left: 2vw;
        padding: 1vw 2vw;
        height: 3.5vw;
        background-color: var(--color-7);
        color: var(--color-4);
        border: 0px;
        border-color: 0vw !important;
        border-radius: 1vw !important;
        font-size: 0.9027777777777777VW;
        font-weight: 800;
        transition: all 300ms;
        cursor: pointer;
        
    }p.addcust:hover{
        background-color: var(--color-1);
    }

    .TblRCus{
      margin-top: 1vw;
      margin-left: 8vw;
    }

    .items{
      display: flex;
    }

    .items p{
      padding: 1vw;
      text-align: center;
       margin-bottom: 0.1vw;
    }

    p.item1{
    
       color: var(--color-1);
      width: 3vw;
    }

     p.item2{
      color: var(--color-1);
       width: 47.5vw;
       overflow: auto;
    
     /*  border-radius:   0vw 2.604166666666667VW 2.604166666666667VW  0vw; */
    }

    p.item3{
     
      color: var(--color-1);
      width: 6vw;
      cursor: pointer;
      transition: all 300ms;
      
    }p.item3:hover{
      color: var(--color-2);
      
    }

    .headertbl{
      display: flex;
    }

    .headertbl p{
      color: white;
      padding: 1vw;
      text-align: center;
      margin-bottom: 0.1vw;
    }

    p.hit1{
      width: 3vw;
       background: var(--color-1);
    }

     p.hit2{
       width: 47.5vw;
        background: var(--color-1);
    }

     p.hit3{
        background: var(--color-2);
      width: 6vw;
    }

    p.pvoid{
      padding: 1vw;
      text-align: center;
      margin-bottom: 0.1vw;
      color:  var(--color-1);
      width: 54.5vw;
    }

    .hrm{
      margin-left: 8vw;
      width: 56.5vw;
    }

 p.naamount{
      color: var(--color-7);
      margin-left: 9vw;
      margin-top: .3vw;
      cursor: pointer;
      font-weight: bold;
      font-size: .7vw;
    }
    p.gencode{
      color: #797979;
      margin-left: 9vw;
      margin-top: .3vw;
      cursor: pointer;
      font-weight: bold;
      font-size: .7vw;
    }
    p.gencode:hover{
      color: var(--color-1);
    }

    /** PRODUCTOS */
    .dflx{
        display: flex;
    }
    .box_content{
        width: 77vw !important;
      
        border-radius: 2.8vw;
        margin-bottom: 5vw;
    }
    .comp_tbl_sec{
        margin-top: 2.65625VW;
    }
    .header_tbl_sec{
        display: flex;
        background: var(--color-1);
        margin-left: -0.007vw;
        height: 4.9VW !important;;
        width: 25.9vw;
    }.header_tbl_sec p{
       height: 4.9VW ;
       font-size: 1.25VW;
       font-style: normal;
       font-weight: 600;
       color: #FFFFFF;
       padding-top: 1.7vw;
       text-align: center;
      
       overflow-x: auto;
    }.htbl1,.itbl1{
        width: 25.9vw;
    }

    .content_tbl_sec{
        margin-bottom: 1vw;
    }
  
    
    .item_cont_tbl{
        display: flex;
        height: 2.9VW ;
        margin-bottom: .5vw;
       
    }.item_cont_tbl p{
        padding-top: .8vw;
        height: 2.9VW ;
        overflow: auto;
        text-align: center;
         font-size: .9vw;
        color: var(--color-8);
        font-weight: 800;
    }

    .itblNA{
        width: 77vw;
        text-align: center;
    }

    .fixsearch{
        margin-top: 2.2vw !important;
        margin-left: 30vw !important;
    }

    #addcupon #navigate-buttons{
      margin-left: 7vw !important;
    }

    .tbls{
      margin-left: 7vw;
    }
    
    


    /** estilos scroll bar */
    .item_cont_tbl p::-webkit-scrollbar {
    -webkit-appearance: none;
    }

    .item_cont_tbl p::-webkit-scrollbar:vertical {
        width:0.5645833333333333VW;
    }

    .item_cont_tbl p::-webkit-scrollbar-button:increment,.item_cont_tbl p::-webkit-scrollbar-button {
        display: none;
    } 

    .item_cont_tbl p::-webkit-scrollbar:horizontal {
        height: 0.3645833333333333VW;
    }

    .item_cont_tbl p::-webkit-scrollbar-thumb {
        background-color: #797979;
        border: 0.10416666666666667VW solid #f1f2f3;
    }

    .item_cont_tbl p::-webkit-scrollbar-track {
        border-radius: 0.3645833333333333VW;  
    }
</style>

